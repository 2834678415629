
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'BlogCard',
  components: {CustomImage},
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
}
